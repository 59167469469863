.forgot-p{
    text-align: center;
    margin-top: 20px;
}
.reset-p{
    margin-top: 20px;
}

.app-auth-bg{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 50px 100px;
}
.eye-password{
    float: right;
    margin-top: -26px;
    color: #6f90a7;
    cursor: pointer;
    margin-right: 0px;
    width: 30px;
    text-align: center;
}
.password-eye{
    width: calc(100% - 33px);
}
.card-app{
    box-shadow: none;
    min-width: 350px;
    width: 100%;

    .card-body{
        padding-top: 0px;
    }
    input{
        font-size: 18px !important;
        border: none;
    }
    input:focus{
        border: none;
        box-shadow: none;
    }

    ::placeholder {
        color: #6f90a7 !important;
        font-size: 18px !important;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #6f90a7 !important;
        font-size: 18px !important;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #6f90a7 !important;
        font-size: 18px !important;
    }
    .btn-login{
        background-color: #6f90a7;
        color: white;
    }
    
    
    // .progress-bar {
    //     background-color: #6f90a7 !important;
    // }
    
}