/* General container styles */
.cdr-table-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    background-color: #ffffff;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
    overflow-x: auto; /* Add horizontal scroll for small screens */
}

/* Title styling */
.table-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

/* Table styling */
.cdr-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-family: Arial, sans-serif;
    min-width: 600px; /* Ensure the table doesn’t shrink too much */
}

.cdr-table th,
.cdr-table td {
    text-align: left;
    padding: 12px 15px;
    border: 1px solid #ddd;
    font-size: 0.95rem;
    white-space: nowrap; /* Prevent text wrapping */
}

/* Header styling */
.cdr-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #555;
    text-transform: uppercase;
}

/* Row styles */
.cdr-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.cdr-table tr:hover {
    background-color: #f1f1f1;
}

/* Empty data styling */
.no-data {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: #666;
    margin-top: 20px;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .cdr-table-container {
        padding: 10px;
        border-radius: 6px;
        margin: 10px;
    }

    .table-title {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .cdr-table {
        width: 100%;
        min-width: unset; /* Remove fixed width for smaller screens */
    }

    .cdr-table th,
    .cdr-table td {
        font-size: 0.85rem; /* Reduce font size for better fit */
        padding: 10px 8px;
    }
}

/* For Extra Small Screens (Mobile View) */
@media (max-width: 480px) {
    .cdr-table th,
    .cdr-table td {
        font-size: 0.8rem;
        padding: 8px 6px;
    }

    .cdr-table-container {
        padding: 8px;
    }

    .table-title {
        font-size: 1rem;
    }
}
/* Header container */
.header-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

/* Back icon */
.back-icon {
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    margin-right: 15px;
    transition: color 0.3s ease;
}

.back-icon:hover {
    color: #007bff; /* Highlight color for hover */
}

/* Header title */
.header-title {
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    margin: 0;
}
/* Call Summary Section */
.call-summary {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.call-summary-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #b2f0dc;
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 15px;
}

.call-summary-icon span{
    font-size: 18px;
    font-weight: 600;
    color: #3cb371;
    width: 28px;
    text-align: center;
}

.call-summary-details {
    flex: 1;
}

.call-summary-name {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    color: #333;
}

.call-summary-extension {
    font-size: 0.95rem;
    color: #666;
    margin: 0;
}

.call-summary-timestamp {
    font-size: 0.85rem;
    color: #999;
    margin: 0;
}

.call-summary-action {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.call-summary-action:hover {
    color: #0056b3;
}
.inbound-call{
    color: #32CD32;
}
.outbound-call{
    color: #4169E1;
}
.missed-call{
    color: red;
}
.call-user-icon{
    grid-area: userIcon;
    padding: 20px 10px 10px 10px;
    
}
.call-user-icon span {
    padding: 21px 16px 16px 16px;
    border-radius: 56px;
    background-color: #A9A9A9;
    
}
.call-user-icon span svg{
    font-size: 24px;
    color: white;
}