.app-settings{
    width: 100%;
    overflow: hidden;
}
.Toastify__toast--info {
    background: #6F90A7 !important;
}

hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    width: 100%;
}

.heading-outer-row{
    margin-bottom: 70px;
}

.heading-container{
    z-index: 100;
    padding: 10px 30px 0px 30px;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h1{
        font-weight: 600;
    } 
}
.sub-heading-container{
    z-index: 100;
    padding: 10px 20px 0px 20px;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h1{
        font-weight: 600;
        text-align: center;
    } 
    svg{
        float: left;
        font-size: 22px;
        margin-top: 9px;
        color: grey;
        margin-left: 6px;
    }
}

.settings-container {
    padding: 5px 20px 5px 20px;
    display: grid;
    grid-template-columns: 50px auto 20px;
    grid-template-rows: auto;
    grid-template-areas: 
        "icon text anchor";
}

.item-icon {
    grid-area: icon;
    font-size: 22px;
    margin-top: 3px;
    color: #6F90A7;
}
.item-text {
    grid-area: text;
    h4{
        margin: 5px;
    }
}
.item-anchor {
    grid-area: anchor;
    font-size: 22px;
    margin-top: 3px;
    color: grey;
}
.vm-select{
    height: 32px;
    border: 1px solid lightgrey;
    border-radius: 4px;
}
.vm-select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40;
}
.visual-vm{
    font-size: 16px;
}
.visual-vm input{
    margin-left: 40px;
    margin-bottom: 10px;
    width: calc(100% - 10px);
}
.vm-copy-save{
    text-align: center;
}

.info-container{
    padding: 10px 20px 10px 20px;
    label{
        font-size: 18px;
        text-align: center;
    }
    .information{
        padding: 10px 30px 10px 30px;
        .phone-icons {
            padding-right: 0;
            input{
                margin-left: 58px;
            }
            svg{
                font-size: 20px;
                float: left;
                margin-left: 16px;
                cursor: pointer;
            }
            p {
                font-size: 12px;
            }
            .radio label::before{
                margin-left: -27px;
                margin-top: -5px;
            }
            .radio label::after{
                left: -2px;
                top: 0px;
            }
        }
        .checkbox-solid-dark label::before {
            width: 22px;
            height: 22px;
            margin-top: -8px;
            margin-left: 6px;
        }
        .group-inout {
            //text-align: center;
            svg{
                font-size: 20px;
                float: left;
                margin-left: 30px;
            }
            p {
                font-size: 12px;
            }
            span{
                font-size: 14px;
            }
        }
        .numbers-dropdown{
            width: 100%;
        }
        .my-phones{
            width: 100%;
            margin-bottom: 10px;
            .radio{
                margin-top: 5px;
            }
        }
        .my-phone-save{
            width: 100%;
            text-align: center;
        }
    }

    .schedule{
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }
        .radio label{
            left: 25px;
            top: -10px;
        }
        
        td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }
        .times-container{
            display: flex;
            .times-input{
                width: 30px;
                padding: 0px 5px;
            }
            /* Chrome, Safari, Edge, Opera */
            .times-input::-webkit-outer-spin-button,
            .times-input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            .times-input[type=number] {
                -moz-appearance: textfield;
            }
            .times-input:focus{
                border-color: none !important;
                box-shadow: none !important;
            }
            label{
                width: 10px;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
        .schedule-save{
            text-align: center;
            margin-top: 10px;
        }
        .remove-btn{
            font-size: 30px;
            margin-left: 24px;
            margin-top: 3px;
            color: #6F90A7;
            cursor: pointer;
        }
    }

    .main-toggle{
        float: right;
        margin-top: -35px;
        i { 
            font-size: 25px;
        }
        svg{ 
            font-size: 25px;
        }
    }

    .call-forward-information{
        padding: 10px 30px 10px 30px;
        .call-control-radio {
            label{
                font-size: 18px;
                margin: 10px 0px 10px 5px;
            }
            input{
                -ms-transform: scale(1.5); /* IE 9 */
                -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
                transform: scale(1.5);
            }
        }
        div{
            padding: 0;
        }
        .call-forward-dropdowns{
            margin-top: 5px;
            select{
                height: 34px;
                margin-bottom: 13px;
                margin-top: 6px;
            }
            input{
                height: 34px;
                margin-bottom: 13px;
                margin-top: 6px;
                padding-left: 20px;
            }
            span{
                position: absolute;
                top: 5.2px;
                left: 12px;
                font-size: 16px;
            }
        }
        .call-forward-save{
            text-align: center;
            margin-top: 10px;
        }
    }

    .phone-icon svg{
        font-size: 24px;
        color: black;
    }

    .radio-success input[type="radio"]:checked + label::before {
        border-color: #6F90A7;
    }
    .radio-success input[type="radio"]:checked + label::after {
        background-color: #6F90A7;
    }
    .radio-success input[type="radio"] + label::before {
        border-color: darkgrey;
    }

    .radio label::before{
        margin-left: -27px;
        margin-top: 4px;
    }
    .radio label::after{
        left: -2px;
        top: 9px;
    }

}

.settings-btn{
    background-color: #6F90A7;
    border-color: #6F90A7;
    color: white;
}

.settings-btn:hover{
    background-color: #54748A;
    border-color: #54748A;
}


////// NEW CSS
/// 
/// 
.modern-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .settings-container-modern {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .settings-container-modern:hover {
    background-color: #f7f7f7;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .item-icon-modern {
    font-size: 1.2rem;
    color: #555;
  }
  
  .item-text-modern {
    flex-grow: 1;
    padding: 0 15px;
    font-size: 1.1rem;
    color: #222;
  }
  
  .item-anchor-modern {
    font-size: 1rem;
    color: #888;
  }
  /* Main Container */
.app-settings-container {
    background-color: #f9f9fb;
    padding: 20px;
    border-radius: 12px;
    max-width: 600px;
    height: 100vh;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  .header-title {
    font-size: 24px;
    color: #333333;
    font-weight: 600;
  }
  
  .settings-container-recording{
    padding: 5px 20px 5px 20px;
    display: grid;
    grid-template-columns: 50px 150px auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "icon text anchor";
    .item-anchor{
        text-align: right;
    }
}