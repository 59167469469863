body{
    background-color: white;
}

.flex{
    display: flex;
}
select{
    background-color: rgba(246, 246, 246, 0.6);
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
}

.cdrs{
    width: 100%;
    overflow: hidden;

    .header{
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background: white;
        z-index: 100;
        .search-container{
            padding: 10px 20px 7px 20px;
            border-bottom: 2px solid lightgrey;
            input{
                border: none;
                //background-color: rgba(246, 246, 246, 0.6);
            }
            input:focus{
                box-shadow: none;
            }
            span{
                position: absolute;
                right: 35px;
                top: 16px;
            }
            svg{
                font-size: 20px;
                color: grey;
            }
        }
    
        .filter-container{
            padding: 10px 20px 10px 20px;
            border-bottom: 1px solid lightgrey;
            box-shadow: 0 5px 4px -2px lightgrey;
            .filter{
                color: #4682B4;
                border-radius: 30px;
                text-align: center;
                padding: 10px 10px 2px 10px;
                cursor: pointer;
            }
            .active-filter{
                border: 1px solid #4682B4;
                font-weight: 600;
            }
        }
    }

    .body{

        margin-top: 120px;

        .date-container{
            padding: 20px 10px 20px 10px;
            label{
                color: #4682B4;
                font-weight: 600;
            }
        }
    
        .call-logs-container {
            display: grid;
            width: 100%;
            grid-template-columns: 70px auto 30px;
            grid-template-rows: auto auto auto;
            grid-template-areas: 
            "userIcon name callStatus"
            "userIcon number callStatus"
            "userIcon date callStatus";
        
            grid-gap: 1px 5px;
            gap: 1px 5px;
            margin: 10px 0px;
            // border-bottom: 1px solid lightgrey;
        
        }
        .call-user-icon{
            grid-area: userIcon;
            padding: 20px 10px 10px 10px;
            span {
                padding: 21px 16px 16px 16px;
                border-radius: 56px;
                background-color: #A9A9A9;
                svg{
                    font-size: 24px;
                    color: white;
                }
            }
        }
    
        .call-user-initials{
            grid-area: userIcon;
            padding: 20px 10px 10px 10px;
            span {
                padding: 20px 13px 16px 13px;
                border-radius: 56px;
                background-color: #7FFFD4;
                label{
                    font-size: 18px;
                    font-weight: 600;
                    color: #3CB371;
                    width: 28px;
                    text-align: center;
                }
            }
        }
    
        .call-status{
            grid-area: callStatus;
            grid-area: callStatus;
            margin-top: 20px;
        }
        .call-status-top {
            .fa-arrow-down{
                transform: rotate(45deg);
                position: relative;
                right: -2px;
                bottom: 6px;
                font-size: 18px;
            }
        
            .fa-arrow-up{
                transform: rotate(45deg);
                position: relative;
                right: -2px;
                bottom: 6px;
                font-size: 18px;
            }
    
            .fa-times{
                position: relative;
                right: -2px;
                bottom: 5px;
                font-size: 18px;
                
            }
            .fa-envelope{
                position: relative;
                right: -2px;
                bottom: 5px;
                font-size: 18px;
                
            }
        }
    
        .call-status-down {
            .fa-phone-alt{
                font-size: 28px;
                position: relative;
                left: -14px;
                top: -23px;
            }
            
        }

        .inbound-call{
            color: #32CD32;
        }
        .outbound-call{
            color: #4169E1;
        }
        .missed-call{
            color: red;
        }

        .inbound-hover:hover i{
            color: #006400;
        }
        .outbound-hover:hover i{
            color: #06327F;
        }
        .missed-hover:hover i{
            color: #D30505;
        }
        
        .call-name {
            grid-area: name;
            font-weight: 600;
            color:black;
        }
        
        .call-number {
            grid-area: number;
            color: #A9A9A9;
        }
        
        .call-date-duration {
            grid-area: date;
            color: #A9A9A9;
        }
        
        hr {
            margin-top: 8px;
            margin-bottom: 8px;
            border: 0;
            border-top: 1px solid rgba(0,0,0,.1);
            box-sizing: content-box;
            height: 0;
            overflow: visible;
            width: 100%;
        }

    }

    .cdr-table{
        border-collapse: collapse;
        margin-left: 6px;
        width: calc(100% - 10px);
        display: none;
        font-size: 11px;
        td, th {
            border: 1px solid darkgrey;
            text-align: left;
            padding: 4px;
        }
        
        tr:nth-child(even) {
        background-color: #dddddd;
        }
    }

}


/////////NEW