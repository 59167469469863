
.main-container{
    display: flex;

    .name-label{
        padding-top: calc(0.375rem + 1px);
        padding-bottom: 0px;
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }
    .checkbox-container{
        text-align: center;
        border: 1px solid lightgrey;
        border-radius: 17px;
    }
    .btn-container{
        text-align: center;
        .btn{
            width: 200px;
        }
    }
    .type-ahead-search-icon {
        position: absolute;
        margin-top: 10px;
        z-index: 1;
        color: grey;
        margin-left: 10px;
    }
    .search-domains input{
        padding-left: 35px;
    }
    .header{
        margin-bottom: 30px;
        .checkbox-main-div{
            text-align: end;
            .checkbox{
                margin-top: 22px;
                margin-left: 5px;
            }
        }
    }

    .engage-domain{
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .mg-20{
        margin-left: 20px;
    }
    .check-all-provision{
        right: 0px;
        top: 20px;
        position: absolute;
    }
    .data-table-icon{
        cursor: pointer;
    }

    .domain-level-toggles{
        text-align: center;
    }

    .domain-level-toggles i{
        cursor: pointer;
    }

    .tenant-refresh{
        font-size: 20px;
        float: right;
        margin-top: -30px;
        margin-right: -30px;
        cursor: pointer;
    }
    .tenant-cross{
        position: absolute;
        right: 55px;
        margin-top: -25px;
        font-size: 16px;
        cursor: pointer;
    }
    .extension-refresh{
        font-size: 20px;
        float: right;
        margin-top: -24px;
        cursor: pointer;
    }
    .mdb-dataTable td{
        word-break: break-all;
        padding: 0.5rem;
    }
    .check-email-btn {
        position: absolute;
        top: 22px;
        right: 22px;
    }
    .table thead.mdb-dataTable-head > tr > th.sorting{
        padding-right: 0.75rem;
    }
    .checkbox-primary-head1{
        margin-left: -3px;
    }
    .checkbox-primary-head1::before{
        top: -5px;
        margin-left: -9px;

    }
    .custom-check-td::before{
        top: -6px;
        left: 7px
    }
}

.react-confirm-alert-overlay{
    z-index: 2000;
}